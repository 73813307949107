import React, { useState, useEffect } from 'react'
import eventsHighlight from 'constant/public-events-highlight.constant'

import { EventCard } from 'components/Public/Home'

//#region STYLES
import styled, { keyframes } from 'styled-components'
//#region ANIMATION
const enter = keyframes`
  from {
    left: 100%,
  }
  to {
    left: 0;
  }
`
const exit = keyframes`
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
`
//#endregion
const Wrapper = styled.section`
  --grid-template-columns: max(2rem, 10vw) 1fr max(2rem, 10vw);
  ${p => p.theme.grid}
  position: relative;
`
const EventsWrapper = styled.div`
  grid-column: 2;
  ${p => p.theme.flex}
  justify-content: space-evenly;
  width: 80vw;
  position: relative;
  flex-wrap: nowrap;
  overflow-x: scroll;
  /* counter-reset: item; */
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  .hidden {
    visibility: hidden;
  }
  .show {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    scroll-snap-align: center;
    animation: ${exit} 3s ease-in-out 1 forwards;
  }
  .next {
    visibility: visible;
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    animation: ${enter} 3s ease-in-out 1 forwards;
  }
  .prev {
    width: 100%;
    position: absolute;
    top: 0;
    left: -100%;
  }
`
const Dots = styled.div`
  ${p => p.theme.flex}
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.45;
  .active {
    background-color: var(--color-gray);
  }
`
const Dot = styled.button`
  --size: clamp(1rem, 1.2vw, 1.5rem);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--color-gray);
  margin-bottom: calc(var(--margin-default) * 1.5);

  &:not(:last-child) {
    margin-right: clamp(0.5rem, 1vw, 1.2rem);
  }
`
//#endregion

/**
 * TODO:
 * 1. fix timing
 * 2. fix onClick
 */
const SlideShow = ({ events }) => {
  const total = events.length
  const indicators = Array.from(Array(total), (_, i) => i)
  const [index, setIndex] = useState(0)

  const onClick = i => {
    setIndex(i)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(prev => (prev === total - 1 ? 0 : prev + 1))
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [index, total])

  return (
    <Wrapper>
      <EventsWrapper>
        {eventsHighlight.map((event, i) => {
          const imageArray = events.filter(ev => event['img'].includes(ev.name))
          const image = imageArray[0].childImageSharp

          return (
            <EventCard
              event={event}
              image={image}
              key={`event-${i}`}
              show={i === index}
              next={i === index + 1 || (i === 0 && index === total - 1)}
              prev={i === index - 1 || (i === total - 1 && index === 0)}
            />
          )
        })}
      </EventsWrapper>

      <Dots>
        {indicators.map((_, i) => (
          <Dot
            key={`idx-${i}`}
            className={i === index && 'active'}
            onClick={() => onClick(i)}
          ></Dot>
        ))}
      </Dots>
    </Wrapper>
  )
}

export default SlideShow

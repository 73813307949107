import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'

import useWindowSize from 'hooks/useWindowSize'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import { Cta, Mission, PastEvents } from 'components/Public/Home'

import styled from 'styled-components'
const PageContentHome = styled(PageContent)`
  background-image: none;
`


const Home = ({ data }) => {
  const { hero_norm, hero_sm, seo, events } = data
  const { title, description } = seo.frontmatter.home

  const windowSize = useWindowSize()
  const heroImage = useCallback(
    () => (windowSize.width < 992 ? hero_sm : hero_norm),
    [windowSize.width, hero_sm, hero_norm]
  )

  const [image, setImage] = useState(heroImage())

  useEffect(() => setImage(heroImage()), [heroImage, windowSize])

  return (
    <PageContentHome>
      <Seo title={title} description={description} />
      <Cta image={image} />
      <Mission />
      <PastEvents events={events} />
    </PageContentHome>
  )
}

export default Home


export const query = graphql`
  {
    hero_norm: file(relativePath: { eq: "hero--norm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }

    hero_sm: file(relativePath: { eq: "hero--port.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }

    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        home {
          title
          description
        }
      }
    }

    events: allFile(filter: { relativeDirectory: { eq: "events" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

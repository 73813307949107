import React from 'react';
import { BgImage } from 'components/Shared/Image';
import { ButtonLink } from 'components/Shared/_Links';
import { ButtonExtLink } from 'components/Shared/_Links'

// announcement file
// import recital from 'assets/files/2023_recital.pdf'

//#region STYLES
import styled from 'styled-components';
const Wrapper = styled.section`
  ${(p) => p.theme.fullbleed}
  position: relative;
  margin-top: calc(0px - var(--header-height));
  width: 100vw;
  height: calc(100vw * 1.198); /*image ratio 9:16*/
  background-image: var(--gradient-cta);
  background-size: cover;

  ${(p) => p.theme.mq.md`
    height: calc(100vw * 0.5622254758418741); 
  `}
`;
const Heading = styled.div`
  width: 95%;
  color: var(--color-white);
  ${(p) => p.theme.absCenter}
  text-align: center;

  * {
    font-weight: 200;
  }

  h1 {
    width: max-content;
    margin: 0 auto;
    padding: 0 3rem;
    font-weight: 400;
    font-size: clamp(2rem, 3vw, 4rem);
    /* letter-spacing: 0.5rem; */
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    /* animation: moveInTop 1s ease-out; */
  }

  h2 {
    font-size: clamp(1.6rem, 2vw, 2.2rem);
    /* letter-spacing: 0.2rem; */
    /* animation: moveInTop 1s 1s ease-out backwards; */
    /* background: rgba(255, 255, 255, 0.3); */
    display: inline-block;
    margin-top: 1rem;
    ${(p) => p.theme.mq.md`
      font-size: 2.5rem;
    `}
  }
`;
const ButtonContainer = styled.div`
  --justify-content: center;
  --flex-direction: column;
  --background-color: var(--color-alert);
  ${(p) => p.theme.flex}
  margin-top: 1rem;

  a {
    font-size: 1.6rem;
    /* letter-spacing: 0.4rem; */
    margin-bottom: 1.3rem;
    /* animation: moveInBottom 1s ease-out; */

    ${(p) => p.theme.mq.md`
      margin-bottom: 1.3rem;
    `}
    &:first-child {
      margin-right: 2.2rem;
    }
  }
`;
const Container = styled.div`
  display: flex;
  /* flex-flow: row wrap; */
`

// const Divider = styled.div`
//   width: 500px;
//   height: 1px;
//   background-color: white;
//   opacity: 0.8;
//   margin-top: 10px;
//   margin-bottom: 20px;
// `
//#endregion

const CTA = ({ image }) => {
  return (
    <Wrapper>
      {/* Hero Images */}
      <BgImage image={image} alt='hero image' />

      <Heading>
        {/* default CTA, use ButtonLink for /pages/ */}
        <h1>邀請您加入晶晶</h1>
        <h2>歡迎熱愛唱歌的大小朋友加入晶晶大家庭</h2>

        {/* ======================================== */}
        {/* REGULAR ANNOUNCEMENT */}
        {/* ======================================== */}

        {/* <ButtonContainer>
          <ButtonLink to='/programs' style={{ fontSize: '2rem' }}>
            晶晶班級介紹
          </ButtonLink>
        </ButtonContainer> */}

        {/* ======================================== */}
        {/* end of default CTA */}

        {/* ======================================== */}
        {/* Enrollment Announcement */}
        {/* ======================================== */}
        {/* external link: <ButtonExtLink href='http://' target='_blank' rel='noreferrer'></ButtonExtLink> */}

        <ButtonContainer>
        {/* <ButtonLink to='/join' style={{ fontSize: '2rem' }}>
            晶晶年度招生中
          </ButtonLink> */}

        {/* <Container> */}
            <ButtonExtLink
              href="https://rb.gy/my8oo"
              target='_blank'
              rel='noreferrer'
            >
              晶晶年度招生中
            </ButtonExtLink>

            <ButtonExtLink
              href="https://shorturl.at/noR24"
              target='_blank'
              rel='noreferrer'
            >
              2024晶晶FUN暑假-五感FUN學習  探索藝世界
            </ButtonExtLink>

            <ButtonExtLink
              href="https://www.opentix.life/event/1768448352343351297"
              inverse='true'
              target='_blank'
              rel='noreferrer'
            >
              2024年度公演音樂會
            </ButtonExtLink>
          {/* </Container> */}

        {/* <Divider /> */}
        </ButtonContainer>
      </Heading>
    </Wrapper>
  );
};

export default CTA;

import React from 'react'
import styled from 'styled-components'

import Title from 'components/Shared/Title'

const Wrapper = styled.section`
  ${ p => p.theme.fullbleed }
  padding: 0 var(--padding-side);
  background-color: var(--color-beige);

  p {
    --margin-bottom: calc(var(--margin-default) * 3);
  }
`

const Mission = () => {
  return (
    <Wrapper>
      <Title>晶晶使命</Title>
      <p>
        晶晶提供合唱音樂教育，期能融合東西方的音樂傳統，讓孩子們在活潑的合唱音樂學習中培養團隊精神，得到熱情、快樂、友誼、與卓越的技巧，成為兒童合唱音樂的文化大使
      </p>
    </Wrapper>
  )
}

export default Mission

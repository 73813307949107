import React from 'react'

import Title from 'components/Shared/Title'
import Slideshow from 'components/Shared/Slideshow'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.section`
  ${p => p.theme.fullbleed}
  grid-column: 1/-1;
  width: 100%;
  background-color: #f0f0f5;
`
const Intro = styled.div`
  p {
    max-width: 40ch;

    ${p => p.theme.mq.sm`
      max-width: 48ch;
    `}
    ${p => p.theme.mq.md`
      max-width: 55ch;
    `}
    ${p => p.theme.mq.lg`
      max-width: 65ch;
    `}
  }
`
//#endregion

const PastEvents = ({ events }) => {
  return (
    <Wrapper>
      <Intro>
        <Title>精彩活動回顧</Title>
        <p>
          在不同的場域及舞台上, 孩子們那些放歌馳騁的身影,
          勾勒著許多的友誼、歡笑與努力。
          大家一起攜手隨著歌聲前進所擺盪的共鳴，不只刻劃了雋永的動人樂句，更堆疊了生命中最多的感動和美好的回憶
        </p>
      </Intro>
      <Slideshow events={events.nodes} />
    </Wrapper>
  )
}

export default PastEvents

/***************************************\

Landing Page Past Events Gallery
data format

{
  title: "event title",
  time: "yyyy.mm.dd",
  img: "img/evt_filename.ext",
  class: "class name",
  desc: "brief description",
  link: "/gallery" --- check this
},

**************************************/

const eventsHighlight = [
  {
    title: "高階班團慶音樂會",
    time: "2019.01.27",
    img: "evt_20190127_bday.jpg",
    class: "紫竹班, Concert班, Youth班",
    desc: "晶晶十八週年團慶音樂會系列(三)高階班音樂會 \n 國立新竹中學音樂廳",
    link: "/gallery"
  },
  {
    title: "2019年冬令音樂營",
    time: "2019.01.25-01.27",
    img: "evt_20190125-0127_wintercamp.JPG",
    class: "紫竹班, Concert班, Youth班",
    desc: "晶晶2019年冬令音樂營 - 桃園復興青年活動中心",
    link: "/gallery"
  },
  {
    title: "森林裡的龍貓",
    time: "2019.01.06",
    img: "evt_20190106_totoro.JPG",
    class: "彩虹班, 綠竹班, 藍竹班",
    desc: "晶晶十八週年團慶音樂會系列(二)-初階班與新竹交響管樂團音樂會(森林裡的龍貓)新竹縣文化局演藝廳",
    link: "/gallery"
  },
  {
    title: "新韻之聲",
    time: "2018.11.18",
    img: "evt_20181118_xinyun.JPG",
    class: "紫竹班",
    desc: "晶晶十八週年團慶音樂會系列(一)-應邀參加台灣合唱協會「新韻之聲詞曲創作音樂會」",
    link: "/gallery"
  },
  {
    title: "大板根森林夏令音樂營",
    time: "2018.08.24-08.25",
    img: "evt_201808_summercamp.JPG",
    class: "彩虹班, 綠竹班, 藍竹班",
    desc: "晶晶2018年夏令音樂營。大板根森林溫泉渡假中心",
    link: "/gallery"
  },
  {
    title: "Vocal Asia Festival for Kids",
    time: "2018.08.11-08.19",
    img: "evt_201808_aca.JPG",
    class: "紫竹班",
    desc: "參加國際兒童阿卡貝拉夏令營(Vocal Asia Festival for Kids) - 桃園,新竹,台中",
    link: "/gallery"
  },
  {
    title: "維也納世界和平合唱藝術節",
    time: "2018.07.23-08.03",
    img: "evt_201807_vienna.JPG",
    class: "Concert班",
    desc: "參加第九屆維也納世界和平合唱藝術節(The 9th World Peace Choral Festival)–Vienna,Austrria",
    link: "/gallery"
  },
  {
    title: "囡仔歌晶好聽",
    time: "2018.06.10",
    img: "evt_201806_17concert.jpg",
    class: "全團",
    desc: "晶晶第十七次年度公演",
    link: "/gallery"
  },
  {
    title: "輕井澤國際合唱節",
    time: "2017",
    img: "evt_karuizawa.jpg",
    class: "Youth班",
    desc: "日本合唱之旅 輕井澤國際合唱節",
    link: "/gallery"
  },
  {
    title: "星光童樂會",
    time: "2017",
    img: "evt_summer.jpg",
    class: "藍竹班",
    desc: "新竹護城河親子公園",
    link: "/gallery"
  },
  {
    title: "阿卡貝拉歡樂頌",
    time: "2017",
    img: "evt_concert.jpg",
    class: "晶晶全團",
    desc: "晶晶第十六次年度公演",
    link: "/gallery"
  },
  {
    title: "加拿大音樂旅行",
    time: "2016",
    img: "images/evt_canada.jpg",
    class: "Concert班",
    desc: "晶晶加拿大音樂旅行",
    link: "/gallery"
  }
]

export default eventsHighlight
import {useState, useEffect} from 'react'
import { isBrowser } from 'services/auth'

const useWindowSize = () => {
  const isSSR = !isBrowser()
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight
  })
  
  const changeWindowSize = () => {
    setWindowSize({width: window.innerWidth, height: window.innerHeight})
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)
    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])
  return windowSize
}

export default useWindowSize

import React from 'react'
import { Image } from 'components/Shared/Image'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  width: 100%;
`
const ImageWrapper = styled.div`
  /* --width: clamp(60vw, 65vw, 90vw); */
  --width: 95vw;
  --height: 53.44vw;
  ${ p => p.theme.mq.md`
  --width: 60vw;
  --height: 36.56vw;
  `}
  ${ p => p.theme.mq.lg`
  --width: 55vw;
  --height: 30.94vw;
  `}
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
  overflow-y: hidden;
`
const Description = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: calc(var(--margin-default)*2.5);

  * {
    --margin-top: -0.2rem;
    --desc-font-size: 0.5rem;
  }
  h3 {
    font-size: var(--font-size-default);
  }
  .time {
    font-size: calc(var(--font-size-default) * 0.6);
    font-weight: 100;
    margin-bottom: 1rem;
  }
  .desc {
    font-size: calc(var(--font-size-default) * 0.8);
  }
`
//#endregion


const EventCard = ({ event, image, show=false, next=false, prev=false }) => {
  return (
    <Wrapper className={`${show ? 'show' : 'hidden'}${next ? ' next' : ''}${prev ? ' prev' : ''}`}>
      <ImageWrapper>
        <Image image={image} alt={event.desc} />
      </ImageWrapper>

      <Description>
        <h3>{event.title}</h3>
        <p className='time'>
          {event.time} – {event.class}
        </p>
        <p className='desc'>{event.desc}</p>
      </Description>
    </Wrapper>
  )
}

export default EventCard
